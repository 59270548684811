import { useCallback, useMemo, useState } from 'react';
import { drawerWidth, groupBy } from '../utils/Common';
import { Menu, MenuItem } from '../utils/Menu';
import { useNavigate } from "react-router-dom";
import { StateContextPropertyType, useStateContext } from '../reducers';
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, SxProps, Theme, Toolbar, Typography } from '@mui/material';
import { KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon } from '@mui/icons-material';

function Sidebar() {
    const {authState, menuState, setMenuState}: StateContextPropertyType = useStateContext();
    const navigate = useNavigate();

    const groupedMenu = useMemo(() =>
        // groupBy(Menu.filter((m) => (authState?.daftar_menu?.length && authState.daftar_menu.filter(am => m.children.filter(c => c.code === am.kode_menu).length > 0).length > 0) || m.groupName === "Profile"), "groupName")
        groupBy(Menu, "groupName")
    , [authState, Menu]);

    const getSelectedGroup = useCallback((param: MenuItem) => {
        if (!groupedMenu?.length || !menuState)
            return;

        for (let i = 0; i < groupedMenu.length; i++)
        {
            if (groupedMenu[i].key === param.groupName)
            {
                setMenuState({...menuState, type: "menuGroup", selectedMenuGroup: param.groupName === menuState.selectedMenuGroup?.groupName ? null : param});
                return;
            }
        }

        setMenuState({...menuState, type: "menuGroup", selectedMenuGroup: undefined});
    }, [menuState, groupedMenu]);

    const toolbar = useMemo(() => {
        return (
            <>
                <Toolbar
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6" noWrap component="div">
                        Menu
                    </Typography>
                </Toolbar>
                <Divider />
            </>
        );
    }, []);

    const mobileToolbar = useMemo(() => {
        return (
            <>
                <Toolbar
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6" noWrap component="div">
                        Menu
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="Buka Menu"
                        edge="start"
                        onClick={() => {
                            setMenuState({...menuState, type: "toggle", showSidebar: !menuState?.showSidebar ?? false});
                        }}
                    >
                        <KeyboardDoubleArrowLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
            </>
        );
    }, [menuState, setMenuState]);

    const drawerList = useMemo(() => {
        const itemStyle: SxProps<Theme> = {
            display: "flex",
            flexDirection: "column",
            width: "100%"
        };
        const itemButtonStyle: SxProps<Theme> = {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
        };
        const itemButtonSubmenuStyle: SxProps<Theme> = {
            paddingLeft: "2.2rem",
            ...itemButtonStyle,
        };
        const subItemContainerStyle: SxProps<Theme> = {
            display: "flex",
            flexDirection: "column",
            width: "100%",
        };
        const itemIconStyle: SxProps<Theme> = {
            marginRight: "5px"
        };

        return (
            <List disablePadding>
            {
                groupedMenu.map((g, idx) => {
                    const subs = g.value.filter(s => s.isSubMenu).map((s, s_idx) => {
                        return (
                            <ListItem
                                key={`submenu_${idx}_${s_idx}`}
                                disablePadding
                                sx={itemStyle}
                            >
                                <ListItemButton
                                    sx={itemButtonSubmenuStyle}
                                    onClick={() => {
                                        // console.log({s});
                                        if (s.id === "Logout" || !s.children?.length)
                                        {
                                            navigate(s.path);
                                            return;
                                        }

                                        const defaultChild = s.children.filter(c => c.isDefault)[0];

                                        if (!defaultChild)
                                        {
                                            navigate(s.path);
                                            return;
                                        }

                                        // console.log({redirectUrl: `${s.path}${defaultChild.path}`});
                                        navigate(`${s.path}${defaultChild.path}`);
                                    }}
                                >
                                {
                                    s.icon ?
                                    <s.icon sx={itemIconStyle} fontSize="small" />
                                    :
                                    null
                                }
                                    <Typography>{s.title}</Typography>
                                </ListItemButton>
                            </ListItem>
                        );
                    });

                    const firstValue = g.value[0];
                    const GroupIcon = firstValue.group.icon;

                    return (
                        <ListItem
                            key={`menu_${idx}`}
                            disablePadding
                            sx={itemStyle}
                        >
                            <ListItemButton
                                sx={itemButtonStyle}
                                onClick={() => {
                                    getSelectedGroup(firstValue);

                                    if (!firstValue.isSubMenu)
                                        navigate(firstValue.path)
                                }}
                            >
                                <GroupIcon sx={itemIconStyle} fontSize="small" />
                                <Typography>{g.key}</Typography>
                            </ListItemButton>
                        {
                            subs.length > 0 &&
                            <Collapse
                                in={menuState?.selectedMenuGroup?.groupName === firstValue?.groupName}
                                timeout="auto"
                                unmountOnExit
                                sx={subItemContainerStyle}
                            >
                                <List disablePadding>
                                {
                                    subs
                                }
                                </List>
                            </Collapse>
                        }
                        </ListItem>
                    );
                })
            }
            </List>
        );
    }, [groupedMenu, getSelectedGroup, menuState]);

    return (
        <>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    open={menuState?.showSidebar}
                    onClose={() => {
                        setMenuState({...menuState, type: "toggle", showSidebar: !menuState?.showSidebar ?? false});
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                {
                    mobileToolbar
                }
                {
                    drawerList
                }
                </Drawer>
                <Drawer
                    open={menuState?.showSidebar}
                    onClose={() => {
                        setMenuState({...menuState, type: "toggle", showSidebar: !menuState?.showSidebar ?? false});
                    }}
                    sx={{
                        display: { sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    variant="persistent"
                >
                {
                    toolbar
                }
                {
                    drawerList
                }
                </Drawer>
            </Box>
        </>
    );
}

export default Sidebar;
