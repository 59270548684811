import { ReactElement } from "react";
import {
    Article as ArticleIcon,
    Apps as AppsIcon,
    InsertChart as InsertChartIcon,
    AccountBox as AccountBoxIcon,
	Settings as SettingsIcon,
    LockReset as LockResetIcon,
    Logout as LogoutIcon,
    Domain as DomainIcon,
    Grading as GradingIcon,
    Construction as ConstructionIcon,
} from '@mui/icons-material';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import GrupBarangList from "../pages/stock/grup_barang/List";
import HargaBarangList from "../pages/sales/harga_barang/List";
import CustomerList from "../pages/sales/customer/List";
import BarangList from "../pages/stock/barang/List";
import KaryawanList from "../pages/global/karyawan/List";
import GudangList from "../pages/stock/gudang/List";
import MenuList from "../pages/global/menu/List";
import Logout from "../pages/Logout";

import { DivisiForm, DivisiList } from "../pages/global/divisi";
import { KategoriBarangForm, KategoriBarangList } from "../pages/stock/kategori_barang";
import Dashboard from "../pages/Dashboard";
import { UserForm, UserList } from "../pages/global/user";
import { PosisiForm, PosisiList } from "../pages/global/posisi";
import { GrupCustomerForm, GrupCustomerList } from "../pages/sales/grup_customer";
import { EstimasiForm, EstimasiList } from "../pages/sales/estimasi";
import { SatuanForm, SatuanList } from "../pages/stock/satuan";
import { RoleForm, RoleList } from "../pages/global/role";
import { KaryawanForm } from "../pages/global/karyawan";
import { MenuForm } from "../pages/global/menu";
import { BarangForm } from "../pages/stock/barang";
import { GrupBarangForm } from "../pages/stock/grup_barang";
import { GudangForm } from "../pages/stock/gudang";
import { HargaBarangForm } from "../pages/sales/harga_barang";
import { CustomerForm } from "../pages/sales/customer";

export type MenuGroupName = 
	"Dashboard" | 
	"Profil" | 
	"Umum" | 
	"Stok" | 
	"Servis"
	;
export type MenuCode = 
	"GLOBAL-DIVISI" |
	"GLOBAL-POSISI" |
	"GLOBAL-JABATAN" |
	"GLOBAL-KARYAWAN" |
	"GLOBAL-USER" |
    "GLOBAL-ROLE" |
	"GLOBAL-MENU" |
	"STOCK-GUDANG" |
	"STOCK-GRUP-BRG" |
	"STOCK-KATEGORI-BRG" |
	"STOCK-SATUAN" |
	"STOCK-BARANG" |
	"SERVICE-GRUP-CUSTOMER" |
	"SERVICE-CUSTOMER" |
	"SERVICE-HARGA-BRG" |
	"SERVICE-ESTIMASI"
;
export type MenuTitle =
    "Divisi" |
	"Jabatan" |
	"Karyawan" |
	"User" |
	"Role" |
	"Menu" |
	"Gudang" |
	"Grup Barang" |
	"Kategori Barang" |
    "Satuan" |
	"Barang" |
	"Grup Customer" |
	"Customer" |
	"Harga Barang" |
    "Estimasi"
;

export class MenuGroup {
    public readonly groupName: MenuGroupName;
    public readonly icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string};

    public constructor (
        groupName: MenuGroupName
    )
    {
        this.groupName = groupName;
        
        switch (groupName)
        {
            case "Dashboard":
                this.icon = InsertChartIcon;
                break;
            case "Profil":
                this.icon = AccountBoxIcon;
                break;
            case "Umum":
                this.icon = DomainIcon;
                break;
            case "Stok":
                this.icon = GradingIcon;
                break;
            case "Servis":
                this.icon = ConstructionIcon;
                break;
            default:
                this.icon = ArticleIcon;
                break;
        }
    }
}

export class MenuItemChild {
    public readonly code: MenuCode;
    public readonly title: string;
    public readonly path: string;
    public readonly component?: ReactElement;
    public readonly formPath?: string;
    public readonly formComponent?: ReactElement;
    public readonly isDefault: boolean;

    public constructor(
        code: MenuCode,
        title: string,
        path: string,
        component?: ReactElement,
        formPath?: string,
        formComponent?: ReactElement,
        isDefault: boolean = false,
    ) {
        this.code = code;
        this.title = title;
        this.path = path;
        this.component = component;
        this.formPath = formPath;
        this.formComponent = formComponent;
        this.isDefault = isDefault;
    }
}

export class MenuItem {
    public readonly group: MenuGroup;
    public readonly groupName: MenuGroupName;
    public readonly id: string;
    public readonly title: string;
    public readonly path: string;
    public readonly icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string};
    public readonly component?: ReactElement;
    public readonly isSubMenu: boolean;
    public readonly isOnlyLink: boolean;
    public readonly formPath?: string;
    public readonly formComponent?: ReactElement;
    public readonly children: MenuItemChild[];

    public constructor(
        groupName: MenuGroupName,
        id: string,
        title: string,
        path: string,
        icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string},
        component?: ReactElement,
        isSubMenu: boolean = true,
        isOnlyLink: boolean = false,
        children: MenuItemChild[] = [],
        formPath?: string,
        formComponent?: ReactElement
    ) {
        this.group = new MenuGroup(groupName);
        this.groupName = groupName;
        this.id = id;
        this.title = title;
        this.icon = icon;
        this.path = path;
        this.component = component;
        this.isSubMenu = isSubMenu;
        this.isOnlyLink = isOnlyLink;
        this.formPath = formPath;
        this.formComponent = formComponent;
        this.children = children;
    }
}

export const Menu: MenuItem[] = [
	// Profil
	new MenuItem("Profil", "Ubah Password", "Ubah Password", "/change-password", LockResetIcon),
    new MenuItem("Profil", "Logout", "Logout", "/logout", LogoutIcon, <Logout />),
    // Dashboard
	new MenuItem("Dashboard", "Dashboard", "Dashboard", "/dashboard", undefined, <Dashboard />, false),
	// Umum
	new MenuItem("Umum", "Master", "Master", "/global-masters", AppsIcon, undefined, true, false, [
		new MenuItemChild("GLOBAL-DIVISI", "Divisi", "/divisi", <DivisiList />, "/divisi/form", <DivisiForm />, true),
		new MenuItemChild("GLOBAL-POSISI", "Jabatan", "/posisi", <PosisiList />, "/posisi/form", <PosisiForm />),
        new MenuItemChild("GLOBAL-KARYAWAN", "Karyawan", "/karyawan", <KaryawanList />, "karyawan/form", <KaryawanForm />),
	]),
	new MenuItem("Umum", "Pengaturan", "Pengaturan", "/global-settings", SettingsIcon, undefined, true, false, [
		new MenuItemChild("GLOBAL-USER", "User", "/user", <UserList />, "/user/form", <UserForm />, true),
        new MenuItemChild("GLOBAL-ROLE", "Role", "/role", <RoleList />, "/role/form", <RoleForm />),
        new MenuItemChild("GLOBAL-MENU", "Menu", "/menu", <MenuList />, "/menu/form", <MenuForm />),
	]),
	// Stok
    new MenuItem("Stok", "Master", "Master", "/stock-masters", AppsIcon, undefined, true, false, [
        new MenuItemChild("STOCK-KATEGORI-BRG", "Kategori Barang", "/kategori-barang", <KategoriBarangList />, "/kategori-barang/form", <KategoriBarangForm />, true),
        new MenuItemChild("STOCK-GRUP-BRG", "Grup Barang", "/grup_barang", <GrupBarangList />, "/grup_barang/form", <GrupBarangForm />),
        new MenuItemChild("STOCK-SATUAN", "Satuan", "/satuan", <SatuanList />, "/satuan/form", <SatuanForm />),
        new MenuItemChild("STOCK-BARANG", "Barang", "/barang", <BarangList />, "/barang/form", <BarangForm />),
        new MenuItemChild("STOCK-GUDANG", "Gudang", "/gudang", <GudangList />, "/gudang/form", <GudangForm />),
	]),
	// Servis
	new MenuItem("Servis", "Master", "Master", "/service-masters", AppsIcon, undefined, true, false, [
        new MenuItemChild("SERVICE-GRUP-CUSTOMER", "Grup Customer", "/grup-customer", <GrupCustomerList />, "/grup-customer/form", <GrupCustomerForm />, true),
        new MenuItemChild("SERVICE-CUSTOMER", "Customer", "/customer", <CustomerList />, "/customer/form", <CustomerForm />),
        new MenuItemChild("SERVICE-HARGA-BRG", "Harga Barang", "/harga_barang", <HargaBarangList />, "/harga_barang/form", <HargaBarangForm />),
        new MenuItemChild("SERVICE-ESTIMASI", "Estimasi", "/estimasi", <EstimasiList />, "/estimasi/form", <EstimasiForm />, true),
    ]),
];
