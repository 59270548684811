import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { NotificationType } from "../utils/Types";

type NotificationDialogType = NotificationType & {
    onClose: () => void,
    showConfirm?: boolean | true,
    confirmButtonText?: "OK" | string,
    onConfirm?: () => void,
};

function NotificationDialog(props: NotificationDialogType) {
    const {onClose, onConfirm} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={true}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">

            </DialogTitle>
            <DialogContent>
                <DialogContentText>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    Close
                </Button>
                <Button onClick={onClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NotificationDialog;
