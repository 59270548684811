import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import FormContainer from "../../../components/FormContainer";
import HargaBarang from '../../../models/HargaBarang';
import { useStateContext } from '../../../reducers';
import DataForm from '../../../components/DataForm';
import Loader from '../../../components/Loader';

function Form() {
	const location = useLocation();
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState<HargaBarang>({ kode: '', harga_barang: 0 });

	useEffect(() => {
		if (!location?.state?.ucode)
            return;

        setLoading(true);
		apiCaller?.get(`harga_barang/${location.state.ucode}`)
            .then((response) => setFormData({...response.data}))
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
	}, [location.state, formData, apiCaller]);

    const onChanged = useCallback((valueObj: any) => {
        const {fieldName, value} = valueObj;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    }, [formData]);

    const onValidate = useCallback((data: HargaBarang | undefined) => {
        return true;
    }, []);

    return (
		<>
        {
            loading && <Loader />
        }
            <FormContainer
                groupName="Servis"
                subgroupName="Master"
                code="SERVICE-HARGA-BRG"
                title="Harga Barang"
            >
                <DataForm<HargaBarang>
                    groupName="Servis"
                    subgroupName="Master"
                    code="SERVICE-HARGA-BRG"
                    title="Harga Barang"
                    data={formData}
                    fields={[
                        {
                            name: "kode",
                            title: "Kode",
                            inputType: "text",
                            dataType: (typeof formData.kode),
                            isReadonly: false,
                            isRequired: formData?.ucode ? true : false,
                            onChanged: (valueObj) => onChanged(valueObj),
                        },
                        {
                            name: "harga barang",
                            title: "Harga barang",
                            inputType: "text",
                            dataType: (typeof formData.harga_barang),
                            isReadonly: false,
                            isRequired: true,
                            onChanged: (valueObj) => onChanged(valueObj),
                        }
                    ]}
                    onValidate={onValidate}
                    onClose={() => {}}
                    onSave={() => {}}
                />
            </FormContainer>		
		</>
    );
};

export default Form;
