import { useStateContext } from "../reducers";
import { MenuCode, MenuGroupName, MenuTitle } from "../utils/Menu";
import BaseContainer from "./_BaseContainer";

type FormContainerType = {
    groupName: MenuGroupName,
    subgroupName: string,
    code: MenuCode,
    title: MenuTitle,
    children: any,
};

function FormContainer(props: FormContainerType) {
    const {children} = props;
    const {menuState} = useStateContext();

    return (
        <BaseContainer>
        {
            children
        }
        </BaseContainer>
    );
};

export default FormContainer;
