import { Input, inputClasses, styled, Toolbar } from "@mui/material";
import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { ReactNode, useEffect, useState } from "react";
import { useStateContext } from "../reducers";
import { drawerWidth } from "../utils/Common";
import clsx from "clsx";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function BaseContainer(props: any) {
    const {menuState} = useStateContext();
    const {children} = props;

    return (
        <>
            <Toolbar />
            <Main open={menuState?.showSidebar} sx={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start"}}>
            {
                children
            }
            </Main>
        </>
    );
};

export default BaseContainer;

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

export const StyledInput = styled(Input)(
    ({ theme }) => `.${inputClasses.input} {
        width: 320px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    
        &:hover {
            border-color: ${blue[400]};
        }
    
        &:focus {
            outline: 0;
            border-color: ${blue[400]};
            box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
        }
    }
  `,
  );
  
export const Label = styled(({ children, className }: { children?: ReactNode; className?: string }) => {
        const formControlContext = useFormControlContext();
        const [dirty, setDirty] = useState(false);

        useEffect(() => {
            if (formControlContext?.filled) {
                setDirty(true);
            }
        }, [formControlContext]);

        if (formControlContext === undefined) {
            return <p>{children}</p>;
        }

        const { error, required, filled } = formControlContext;
        const showRequiredError = dirty && required && !filled;

        return (
            <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
                {children}
                {required ? ' *' : ''}
            </p>
        );
    },
    )`
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    margin-bottom: 4px;

    &.invalid {
        color: red;
    }
`;
  
export const HelperText = styled((props: {}) => {
        const formControlContext = useFormControlContext();
        const [dirty, setDirty] = useState(false);
    
        useEffect(() => {
            if (formControlContext?.filled) {
                setDirty(true);
            }
        }, [formControlContext]);
    
        if (formControlContext === undefined) {
            return null;
        }
    
        const { required, filled } = formControlContext;
        const showRequiredError = dirty && required && !filled;
    
        return showRequiredError ? <p {...props}>This field is required.</p> : null;
    })`
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
`;
