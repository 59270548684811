import { useEffect, useMemo, useState } from 'react';
import './App.css';
import StateContext, { usePersistedReducer } from './reducers';
import authReducer, { authInitialState } from './reducers/auth';
import menuReducer, { menuInitialState } from './reducers/menu';
import ApiCaller from './utils/ApiTemplate';
import { BrowserRouter } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import { Box, CssBaseline, GlobalStyles } from '@mui/material';
import { GlobalStyleTemplate } from './styles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AppRoutes, { OtherRoutes } from './utils/Routes';

function App() {
    const { state: authState, dispatch: setAuthState } = usePersistedReducer(authReducer, authInitialState, process.env.REACT_APP_AUTH_PERSIST_STORAGE_KEY);
    const { state: menuState, dispatch: setMenuState } = usePersistedReducer(menuReducer, menuInitialState, process.env.REACT_APP_MENU_PERSIST_STORAGE_KEY);
    const [ showMenu, setShowMenu ] = useState(false);

    const providerState = useMemo(() => {
        const apiCaller = new ApiCaller({authState});

        return {
            authState,
            setAuthState,
            menuState,
            setMenuState,
            apiCaller,
        };
    }, [authState, setAuthState, menuState, setMenuState]);

    useEffect(() => {
        // console.log({authState, username: authState.username, token: authState.token});
        setShowMenu(authState && authState.username && authState.token ? true : false);
    }, [authState, setAuthState]);

    // useEffect(() => console.log({showMenu}), [showMenu]);

    return (
        <StateContext.Provider value={providerState}>
            <BrowserRouter>
                <GlobalStyles styles={GlobalStyleTemplate} />
				<Box
                    component="main"
                    sx={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        padding: 0, // Ensure no padding is applied
                        overflowX: 'hidden' // Hide any overflow on X-axis
                    }}
                >
                    <CssBaseline />
                    {showMenu ? (
                        <>
                            <Navbar />
                            <Sidebar />
                            <Box
                                // onClick={() => {
                                //     if (menuState && menuState.showSidebar)
                                //         setMenuState({
                                //             ...menuState,
                                //             type: "toggle",
                                //             showSidebar: false,
                                //         });
                                // }}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                }}
                            >
                                <AppRoutes />
                            </Box>
                            <Footer />
                        </>
                    ) : (
                        <OtherRoutes />
                    )}
                </Box>
            </BrowserRouter>
        </StateContext.Provider>
    );
}

export default App;
