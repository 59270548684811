import jsPDF from "jspdf";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Menu, MenuGroupName, MenuItemChild } from "./Menu";

export const SO_STATUS_PROCESS: string = "SO-PROCESS";
export const SO_STATUS_PROCESS_NAME: string = "Proses";
export const SO_STATUS_CONFIRMED: string = "SO-CONFIRMED";
export const SO_STATUS_CANCELED: string = "SO-CANCELED";
export const SO_STATUS_PACKING: string = "SO-PACKING";
export const SO_STATUS_SENDING: string = "SO-SENDING";
export const SO_STATUS_DELIVERED: string = "SO-DELIVERED";
export const SO_STATUS_WP: string = "SO-WP";
export const SO_STATUS_COMPLETED: string = "SO-COMPLETED";
export const SJ_STATUS_NEW: string = "SJ-NEW";
export const SJ_STATUS_NEW_NAME: string = "Baru";
export const SJ_STATUS_CONFIRMED: string = "SJ-CONFIRMED";
export const SJ_STATUS_CANCELED: string = "SJ-CANCELED";
export const SJ_STATUS_SENDING: string = "SJ-SENDING";
export const SJ_STATUS_DELIVERED: string = "SJ-DELIVERED";
export const SHOWN_ERROR_CODE: number = 599;
export const XLSX_FILE_TYPE: string = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
export const XLSX_FILE_TYPE_DROPZONE: string = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const XLSX_FILE_EXTENSION: string = ".xlsx";
export const PNG_FILE_TYPE_DROPZONE: string = "image/png";
export const PNG_FILE_EXTENSION: string = ".png";
export const JPG_FILE_TYPE_DROPZONE: string = "image/jpg";
export const JPG_FILE_EXTENSION: string = ".jpg";
export const JPEG_FILE_TYPE_DROPZONE: string = "image/jpeg";
export const JPEG_FILE_EXTENSION: string = ".jpeg";
export const DATE_FORMAT_DEFAULT = "YYYY-MM-DD";
export const DATETIME_FORMAT_DEFAULT = "YYYY-MM-DDTHH:mm:ss";
export const maxFileSize: number = 5242880;
export const maxRowUpload: number = 10000;
export const maxRowCountOnSingleProcess: number = 50;
export const headerHeight = 64;
export const pageTabHeight = 48;
export const pageToolbarHeight = 50;
export const drawerWidth = 240;
export const footerHeight = 40;

export function groupBy<T, K extends keyof T>(items: T[], key: K) {
	const map = new Map<T[K], T[]>();
    items.forEach(item => {
        const itemKey = item[key];

        if (!map.has(itemKey))
            map.set(itemKey, items.filter(i => i[key] === item[key]));
    })
    return Array.from(map, ([key, value]) => ({key, value}));
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    // console.log({width, height});
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export function getCurrencyDisplay(value: number | bigint) {
    const localeSetting = `${process.env.REACT_APP_LOCALE_LANG}-${process.env.REACT_APP_LOCALE_COUNTRY}`;
    return Intl.NumberFormat(localeSetting, {style: "currency", currency: process.env.REACT_APP_LOCALE_CURRENCY}).format(value);
}

export function getDecimalDisplay(value: number | bigint) {
    const localeSetting = `${process.env.REACT_APP_LOCALE_LANG}-${process.env.REACT_APP_LOCALE_COUNTRY}`;
    const valueToParse: number = typeof value === "number" ? parseFloat(value.toFixed(2)) : Number(value);
    return Intl.NumberFormat(localeSetting, {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valueToParse);
}

export function componentToHex(c: bigint) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function wordings(value: number): string
{
    value = Math.abs(value);
    const singular = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan", "sepuluh", "sebelas"];

    if (value < 12)
        return `${value === 0 ? "" : " "}${singular[Math.floor(value)]}`;
    
    if (value < 20)
        return `${wordings(value - 10)} belas`;
    
    if (value < 100)
        return `${wordings(value / 10)} puluh${wordings(value % 10)}`;
    
    if (value < 200)
        return ` seratus${wordings(value - 100)}`;
    
    if (value < 1000)
        return `${wordings(value / 100)} ratus${wordings(value % 100)}`;
    
    if (value < 2000)
        return ` seribu${wordings(value - 1000)}`;
    
    if (value < 1000000)
        return `${wordings(value / 1000)} ribu${wordings(value % 1000)}`;
    
    if (value < 1000000000)
        return `${wordings(value / 1000000)} juta${wordings(value % 1000000)}`;
    
    if (value < 1000000000000)
        return `${wordings(value / 1000000000)} milyar${wordings(value % 1000000000)}`;
    
    if (value < 1000000000000000)
        return `${wordings(value / 1000000000000)} trilyun${wordings(value % 1000000000000)}`;

    return "";
}

export function numberToWords(value: number): string
{
    if (value < 0)
        return `minus ${wordings(value).trim()}`;
    
    return wordings(value).trim();
}

export const exportPdf = (target: any, gridId: any, name: string, orientation?: "p" | "portrait" | "landscape" | "l", pagesize?: string | number[], debug: boolean = false) => {
    document.querySelector(target).style.width = orientation === "p" ? (pagesize === "a4" ? "210mm" : "184.5mm") : (pagesize === "a4" ? "297mm" : "210mm");
    document.querySelector(target).style.height = orientation === "p" ? (pagesize === "a4" ? "297mm" : "210mm") : (pagesize === "a4" ? "210mm" : "184.5mm");

    const pdf = new jsPDF(orientation, "pt", pagesize);
    pdf.html(document.querySelector(target), {
        callback: () => {
            debug ? window.open(pdf.output("bloburl")) : pdf.save(`${name}.pdf`);
            document.querySelector(gridId).className = "table";
        },
    });
};
  
export function separateBase64String(b64: string) {
    const splitted = b64.split(",");
    const mimeType = splitted[0].split(";")[0].split(":")[1];
    const base64Str = splitted[1];
    return {
        mimeType: mimeType,
        base64Str: base64Str
    };
}

export function objectWithoutProperties(obj: any, exceptionKeys: any[]) {
    const target: any = {};
    for (const i in obj) {
        if (exceptionKeys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

export function dateToString(date: Moment, format: string = "YYYY-MM-DD") {
    return moment(date).format(format);
}

export function dateLocalToUtc(date: string, format: string) {
    const timezoneOffset = (new Date()).getTimezoneOffset();
    return moment(date).add(timezoneOffset, "m").format(format);
}

export function escapeRegExp(value: string) {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export interface PageContainerActionParamType extends MenuItemChild {
    mainPath: string;
};

export function getPathObject(groupName: MenuGroupName, subgroupName: string, code: string): PageContainerActionParamType | null {
    const currentMenu = Menu.filter(m => m.groupName === groupName && m.id === subgroupName && m.children.length)[0];

    if (!currentMenu)
        return null;

    const currentMenuChild = currentMenu.children.filter(c => c.code === code)[0];

    if (!currentMenuChild)
        return null;

    return {...currentMenuChild, mainPath: currentMenu.path};
};