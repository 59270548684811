import { Dispatch, SetStateAction } from "react";
import { MenuItem } from "../../utils/Menu";

export type MenuActionType = "toggle" | "background" | "menuGroup";
export type MenuStateType = {showSidebar: boolean, showBackground?: boolean, selectedMenuGroup?: MenuItem};
export type MenuStateActionType = {type: MenuActionType, showSidebar: boolean, showBackground?: boolean, selectedMenuGroup?: MenuItem | null};

export const menuInitialState: MenuStateType = {showSidebar: false, showBackground: false};

export default function menuReducer(state: any, action: MenuStateActionType) : MenuStateType {
    // console.log({state, action});
    switch (action.type) {
        case 'toggle':
            return {...state, showSidebar: action.showSidebar};
        case 'background':
            return {...state, showBackground: action.showBackground};
        case 'menuGroup':
            return {...state, selectedMenuGroup: action.selectedMenuGroup};
        default:
            throw new Error();
    }
}
