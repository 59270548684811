import { useCallback, useState } from "react";
import DataList from "../../../components/DataList";
import Role from "../../../models/Role";
import { useStateContext } from "../../../reducers";
import { DefaultMenuTypes, NotificationType } from "../../../utils/Types";
import PageContainer from "../../../components/PageContainer";
import NotificationDialog from "../../../components/NotificationDialog";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
    Add as AddIcon,
    FileDownload as FileDownloadIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
} from '@mui/icons-material';

function List() {
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [error, setError] = useState<NotificationType | null>(null);
    const [loading, setLoading] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<Role | null>(null);
    const [dataToDelete, setDataToDelete] = useState<Role | null>(null);
    const [dataToView, setDataToView] = useState<Role | null>(null);
    const navigate = useNavigate();

	const handleEditClick = useCallback(() => {
        if (!dataToEdit)
            return;

        navigate(`/role/edit/${dataToEdit.ucode}`);
    }, [navigate, dataToEdit]);

	const handleDeleteClick = useCallback(() => {
        if (!dataToDelete)
            return;

        navigate(`/role/edit/${dataToDelete.ucode}`);
    }, [navigate, dataToDelete]);

    return (
        <>
        {
            loading && <Loader />
        }
            <PageContainer
                groupName="Umum"
                subgroupName="Pengaturan"
                code="GLOBAL-ROLE"
                title="Role"
                toolbars={[
                    {
                        id: "add",
                        title: "Tambah",
                        icon: AddIcon,
                        action: () => {},
                    },
                    {
                        id: "exportExcel",
                        title: "Export to Excel",
                        icon: FileDownloadIcon,
                        action: () => {},
                        color: "secondary",
                    },
                ]}
            >
                <DataList<Role>
                    dataType={Role}
                    dataFieldsToShow={["kode", "nama"]}
                    setError={(errorState) => setError(errorState ?? null)}
                    setLoading={(loadingState) => setLoading(loadingState ?? false)}
                    actions={[
                        {
                            id: "Edit" as DefaultMenuTypes,
                            title: "Ubah",
                            action: (item) => setDataToEdit(item),
                            icon: EditIcon,
                            color: "edit",
                        },
                        {
                            id: "View" as DefaultMenuTypes,
                            title: "Lihat",
                            action: (item) => setDataToView(item),
                            icon: SearchIcon,
                            color: "view",
                        },
                        {
                            id: "Delete" as DefaultMenuTypes,
                            title: "Hapus",
                            action: (item) => setDataToDelete(item),
                            icon: DeleteIcon,
                            color: "delete",
                        },
                    ]}
                    apiCaller={apiCaller}
                    endpoint="role"
                    titleColumnName="nama"
                    subtitleColumnName="kode"
                />
            </PageContainer>
        {
            dataToView ?
            <></>
            :
            null
        }
        {
            error ?
            <NotificationDialog
                {...error}
                onClose={() => setError(null)}
            />
            :
            null
        }
        </>
    );
};

export default List;
