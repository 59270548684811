import BaseModel from "./_BaseModel";

export default class Customer extends BaseModel {
    public readonly ucode?: string;
    public nama?: string;
    public alamat?: string;
    public no_telp?: string;    
    public aktif?: boolean = true;

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
}