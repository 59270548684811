import UserRole from "./UserRole";
import BaseModel from "./_BaseModel";

export default class User extends BaseModel {
    public readonly ucode?: string;
    public ucode_karyawan?: string;
    public username?: string;
    public password?: string;
    public aktif: boolean = true;
    public userRoles: UserRole[] = [];

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
}