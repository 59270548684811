import { Container } from '@mui/material';
import { ReactElement } from 'react';
import { useWindowDimensions } from '../utils/Common';

function Dashboard(): ReactElement {
    const {height} = useWindowDimensions();

    return (
        <Container maxWidth="xl">

        </Container>
    );
}

export default Dashboard;
