import { Box, Typography } from "@mui/material";
import moment from "moment";
import { StateContextPropertyType, useStateContext } from "../reducers";
import { drawerWidth, footerHeight } from "../utils/Common";

function Footer() {
    const {menuState, setMenuState}: StateContextPropertyType = useStateContext();

    return (
        <Box
            className="footer bg-color-default"
            onClick={() => {
                if (menuState && menuState.showSidebar)
                    setMenuState({
                        ...menuState,
                        type: "toggle",
                        showSidebar: false,
                    });
            }}
            sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                color: "common.white",
                width: { md: menuState?.showSidebar ? `calc(100vw - ${drawerWidth}px)` : "100vw" },
                ml: { md: menuState?.showSidebar ? `${drawerWidth}px` : 0 },
                height: footerHeight,
            }}
        >
            <Typography component="span" sx={{fontSize: "0.75rem"}}>Copyright © <a href="https://www.novitate.net/" rel="noreferrer noopener" target="_blank">Novitate</a> {moment().format("YYYY")}. All Rights Reserved.</Typography>
        </Box>
    )
};

export default Footer;
