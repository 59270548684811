import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useStateContext } from '../reducers';
import { AuthStateActionType } from '../reducers/auth';

const Logout: React.FC = () => {
    const navigate = useNavigate();
    const {authState, setAuthState, setMenuState, apiCaller} = useStateContext();
    const [loading, setLoading] = useState(false);

    const handleLogout = useCallback(() => {
        if (!apiCaller)
            return;

        setLoading(true);

        apiCaller.post(`users/logout`, {...authState})
            .then((response) => {
                // console.log({response});
                const newAuthState: AuthStateActionType = {
                    type: "logout"
                };
                setAuthState(newAuthState);
                setMenuState({type: "toggle", showSidebar: false, showBackground: false});
                setLoading(false);
                navigate('/');
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, setAuthState, authState, setMenuState, navigate]);

    useEffect(() => {
        setAuthState({type: "logout"});
        setMenuState({type: "toggle", showSidebar: false, showBackground: false});
        navigate('/');
    }, []);

    return (
        <>
        {
            loading && <Loader />
        }
        </>
    );
};

export default Logout;
