import React, { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, InputAdornment } from '@mui/material';
import { AccountCircle, Lock } from '@mui/icons-material'; // Importing Material-UI icons for demonstration
import { useStateContext } from '../reducers';
import Loader from '../components/Loader';

interface IFormInput {
	email: string;
	password: string;
}

const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>();
    const navigate = useNavigate();
    const {apiCaller, setAuthState} = useStateContext();
    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<IFormInput> = useCallback((data) => {
        if (!data.email || !data.password)
        {
            alert("Username dan password harus diisi.");
            return;   
        }

        if (!apiCaller)
            return;

        setLoading(true);

        apiCaller.post(`user/login`, {
            username: data.email,
            password: data.password,
        })
            .then(response => {
                const {data: {data: responseData}} = response;
                setAuthState({
                    type: 'login',
                    username: responseData.username,
                    nama_karyawan: responseData.nama_karyawan,
                    ucode_divisi: responseData.ucode_divisi,
                    kode_divisi: responseData.kode_divisi,
                    level: responseData.level,
                    token: responseData.token,
                    daftar_menu: responseData.daftar_menu,
                });
                setLoading(false);
                navigate('/dashboard');
            })
            .catch(error => {
                console.error((error));
                setLoading(false);
            })
        ;
    }, [apiCaller, setAuthState, navigate]);

    return (
        <>
        {
            loading && <Loader />
        }
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                }}
            >
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="mb-3">
                                <label className="form-label">Email Address</label>
                                <div className="form-control-feedback form-control-feedback-start">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        placeholder="Email Address"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle className="ph-user-circle text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    {
                                        ...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: 'Invalid email address',
                                            },
                                        })
                                    }
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email.message : ''}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <div className="form-control-feedback form-control-feedback-start">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="password"
                                        type="password"
                                        placeholder="Password"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock className="ph-lock text-muted" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    {
                                        ...register('password', {
                                            required: 'Password is required',
                                            minLength: {
                                                value: 6,
                                                message: 'Password must be at least 6 characters',
                                            },
                                        })
                                    }
                                        error={!!errors.password}
                                        helperText={errors.password ? errors.password.message : ''}
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className="btn btn-primary w-100"
                                >
                                    Sign in
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Box>
        </>
    );
};

export default Login;
