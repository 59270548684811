import { useState } from "react";
import DataList from "../../../components/DataList";
import User from "../../../models/User";
import { useStateContext } from "../../../reducers";
import { DefaultMenuTypes, NotificationType } from "../../../utils/Types";
import PageContainer from "../../../components/PageContainer";
import NotificationDialog from "../../../components/NotificationDialog";
import Loader from "../../../components/Loader";

function List() {
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [error, setError] = useState<NotificationType | null>(null);
    const [loading, setLoading] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<User | null>(null);
    const [dataToDelete, setDataToDelete] = useState<User | null>(null);
    const [dataToView, setDataToView] = useState<User | null>(null);

    return (
        <>
        {
            loading && <Loader />
        }
            <PageContainer
                groupName="Umum"
                subgroupName="Pengaturan"
                code="GLOBAL-USER"
                title="User"
            >
                <DataList<User>
                    dataType={User}
                    dataFieldsToShow={["kode", "nama"]}
                    useDummyData={true}
                    setError={(errorState) => setError(errorState ?? null)}
                    setLoading={(loadingState) => setLoading(loadingState ?? false)}
                    actions={[
                        {
                            id: "Edit" as DefaultMenuTypes,
                            title: "Ubah",
                            action: (item) => setDataToEdit(item),
                        },
                        {
                            id: "Delete" as DefaultMenuTypes,
                            title: "Hapus",
                            action: (item) => setDataToDelete(item),
                        },
                        {
                            id: "View" as DefaultMenuTypes,
                            title: "Lihat",
                            action: (item) => setDataToView(item),
                        },
                    ]}
                />
            </PageContainer>
        {
            dataToView ?
            <></>
            :
            null
        }
        {
            error ?
            <NotificationDialog
                {...error}
                onClose={() => setError(null)}
            />
            :
            null
        }
        </>
    );
};

export default List;