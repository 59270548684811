import React from 'react';
import { 
	Button, 
	Modal, 
	Box, 
	TextField, 
	Typography,
} from '@mui/material';

type DataListReadModalProps = {
    open: boolean;
    handleClose: () => void;
    data: any;
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,  // Increase the width
    maxHeight: '80vh',  // Limit the maximum height
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',  // Add vertical scrollbar if content overflows
};

const DataListReadModal: React.FC<DataListReadModalProps> = (props) => {
	const { open, handleClose, data } = props;
	console.log("data: ", data);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <TextField fullWidth margin="normal" id="ucode" label="ucode" variant="outlined" defaultValue={data.ucode} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="nama" label="nama" variant="outlined" defaultValue={data.nama} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="logo" label="logo" variant="outlined" defaultValue={data.logo} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="keterangan" label="keterangan" variant="outlined" defaultValue={data.keterangan} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="kode" label="kode" variant="outlined" defaultValue={data.kode} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="kota" label="kota" variant="outlined" defaultValue={data.kota} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="alamat" label="alamat" variant="outlined" defaultValue={data.alamat} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="npwp" label="npwp" variant="outlined" defaultValue={data.npwp} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="parent" label="parent" variant="outlined" defaultValue={data.parent} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="rek_name" label="rek_name" variant="outlined" defaultValue={data.rek_name} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="rek_number" label="rek_number" variant="outlined" defaultValue={data.rek_number} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="rek_qr" label="rek_qr" variant="outlined" defaultValue={data.rek_qr} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="aktif" label="aktif" variant="outlined" defaultValue={data.aktif} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="timezone" label="timezone" variant="outlined" defaultValue={data.timezone} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="tip_file_logo" label="tip_file_logo" variant="outlined" defaultValue={data.tipe_file_logo} InputProps={{ readOnly: true }} />
                <TextField fullWidth margin="normal" id="tlp" label="tlp" variant="outlined" defaultValue={data.tlp} InputProps={{ readOnly: true }} />
                <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>Close</Button>
            </Box>
        </Modal>
    );
};

export default DataListReadModal;
