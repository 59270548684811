import {
	useCallback, 
	useEffect, 
	useMemo, 
	useState
} from "react";
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	CardActions,
	Grid,
	SvgIconTypeMap,
	Typography,
	CardActionArea,
    ButtonGroup,
    ThemeProvider,
    Box,
    Tooltip,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import BaseModel from "../models/_BaseModel";
import ApiCaller from "../utils/ApiTemplate";
import { generateRandomData } from "../utils/Data";
import { ApiRequestType, DefaultMenuTypes, FunctionObjectType, NotificationType } from "../utils/Types";
import '../../src/styles/all.css';
import '../../src/styles/fonts/inter/inter.css';
import "../../src/styles/images/busi_daihatsu.jpg";
import imgtest from '../../src/styles/images/busi_daihatsu.jpg';
import DataListReadModal from '../components/DataListModalPage';
import { Info as InfoIcon } from '@mui/icons-material';
import { GridColorStyles } from "../expanded-theme";

type GridRowActionType<T> = {
    id: DefaultMenuTypes | string,
    title: string,
    icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string},
    action: (item: T) => void,
    color?: "edit" | "delete" | "view" | "dark",
    variant?: "text" | "contained" | "outlined",
};

type GridRowDescriptionType = {
    fieldName: string,
    title: string,
};

type DataListPropertyType<T extends BaseModel> = {
    dataType: {new(): T},
    apiCaller?: ApiCaller,
    endpoint?: string,
    apiMethod?: ApiRequestType,
    dataFieldsToShow: string[],
    colCountInRow?: number,
    maxRowInPage?: number,
    setLoading: FunctionObjectType<boolean>,
    setError: FunctionObjectType<NotificationType> | null,
    actions?: GridRowActionType<T>[],
    useDummyData: boolean,
    titleColumnName?: string,
    subtitleColumnName?: string,
    imgBase64?: string,
    details?: GridRowDescriptionType[],
    needReload?: boolean,
};

function DataList<T extends BaseModel>(props: DataListPropertyType<T>) {
    const { dataType, useDummyData = false, setError, setLoading, actions, apiCaller, endpoint, apiMethod, colCountInRow, maxRowInPage, titleColumnName, subtitleColumnName, imgBase64, details, needReload = false } = props;
    const [data, setData] = useState<T[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedData, setSelectedData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const loadData = useCallback(() => {
        if (useDummyData) {
            setData(generateRandomData(dataType, maxRowInPage ?? 32));
            return;
        }

        if (!apiCaller || !endpoint)
            return;

        const requestMethod: ApiRequestType = apiMethod ?? "get";

        const apiCallFunc = requestMethod === "get" ? apiCaller.get(endpoint) : (requestMethod === "post" ? apiCaller.post(endpoint) : null);

        if (!apiCallFunc)
            return;

        setIsLoading(true);

        apiCallFunc
            .then((response) => {
                const newData: T[] = response.data.data;
                setData(newData);
            })
            .catch((error) => {
                const errorObj: NotificationType = {
                    title: error.response?.statusText ?? "Error",
                    message: error?.response?.message ?? "system error",
                    type: "error",
                }

                if (setError)
                    setError(errorObj);
            })
            .finally(() => setIsLoading(false))
    }, [apiCaller, endpoint, apiMethod, setError, maxRowInPage, useDummyData, dataType]);

    useEffect(() => {
        if (!needReload)
            return;

        loadData();
    }, [needReload]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedData(null);
    };

    const boxStyle = useMemo(() => {
        return data.length ? {display: "flex", justifyContent: "center", width: "100%"} : {display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "70vh", width: "100%"}
    }, [data]);

    return (
        <>
            <Box sx={{...boxStyle}}>
            {
                data?.length ?
                <Grid container spacing={2}>
                {
                    data.map((d, idx) => (
                        <Grid
                            item
                            lg={12 / (colCountInRow ?? 4)}
                            md={6}
                            sm={12}
                            key={`data_cell_${idx}`}
                        >
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                    <CardHeader
                                        title={useDummyData ? "Busi motor" : (d as any)[titleColumnName ?? ""] ?? "-"}
                                        titleTypographyProps={{ variant: "h6" }}
                                        subheader={useDummyData ? "September 14, 2016" : (d as any)[subtitleColumnName ?? ""] ?? "-"}
                                        sx={{padding: "0.5rem"}}
                                    />
                                {
                                    useDummyData || imgBase64 ?
                                    <CardMedia
                                        component="img"
                                        height="194"
                                        image={useDummyData ? imgtest : imgBase64}
                                        alt="img"
                                    />
                                    :
                                    null
                                }
                                {
                                    useDummyData || details ?
                                    <CardContent sx={{padding: "0.5rem"}}>
                                        <Typography variant="body2" color="text.secondary">
                                        {
                                            useDummyData ?
                                            <span>Busi(SC16HR11) Iridium original</span>
                                            :
                                            details?.map((dt, dt_idx) => {
                                                return (
                                                    <Box
                                                        sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%"}}
                                                        key={`data_cell_details_${idx}_${dt_idx}`}
                                                    >
                                                        <Grid item lg={3} md={3} sm={3}><span>{dt.title}</span></Grid>
                                                        <Grid item lg={9} md={9} sm={9}><span><b>{(d as any)[dt.fieldName] ?? "N/A"}</b></span></Grid>
                                                    {
                                                        dt_idx === details?.length - 1 ? null : <br/>
                                                    }
                                                    </Box>
                                                );
                                            })
                                        }
                                        </Typography>
                                    </CardContent>
                                    :
                                    null
                                }
                                </CardActionArea>
                                <CardActions disableSpacing sx={{ display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "end"}}>
                                    <ThemeProvider theme={GridColorStyles}>
                                        <ButtonGroup>
                                        {
                                            actions?.map((a, a_idx) => (
                                                <Tooltip
                                                    key={`data_cell_action_${idx}_${a_idx}`}
                                                    title={a.title}
                                                    arrow
                                                >
                                                    <Button size="small" variant={a.variant ?? "contained"} color={a.color ?? "primary"} onClick={() => a.action(d)} sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                                                    {
                                                        a.icon ? <a.icon fontSize="small" sx={{marginRight: "0.1rem", paddingBottom: 0}} /> : null
                                                    }
                                                    </Button>
                                                </Tooltip>
                                            ))
                                        }
                                        </ButtonGroup>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
                </Grid>
                :
                (
                    !isLoading ?
                    <Box sx={{color: "gray", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <InfoIcon fontSize="large" />
                        <h2>Data tidak ditemukan.</h2>
                    </Box>
                    :
                    null
                )
            }
            </Box>
            {selectedData && (
                <DataListReadModal 
					open={openModal} 
					handleClose={handleCloseModal} 
					data={selectedData} 
				/>
            )}
        </>
    );
};

DataList.defaultProps = {
    useDummyData: false,
};

export default DataList;
