import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import FormContainer from "../../../components/FormContainer";
import Karyawan from '../../../models/Karyawan';
import { useStateContext } from '../../../reducers';
import DataForm from '../../../components/DataForm';
import Loader from '../../../components/Loader';

function Form() {
	const location = useLocation();
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState<Karyawan>({ ucode: '', nama: '' });

	useEffect(() => {
		if (!location?.state?.ucode)
            return;

        setLoading(true);
		apiCaller?.get(`karyawan/${location.state.ucode}`)
            .then((response) => setFormData({...response.data}))
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
	}, [location.state, formData, apiCaller]);

    return (
        <>
        {
            loading && <Loader />
        }
            <FormContainer
                groupName="Umum"
                subgroupName="Master"
                code="GLOBAL-KARYAWAN"
                title="Karyawan"
            >
                <DataForm<Karyawan>
                    groupName="Umum"
                    subgroupName="Master"
                    code="GLOBAL-KARYAWAN"
                    title="Karyawan"
                    data={formData}
                    fields={[
                        {
                            name: "nama",
                            title: "Nama",
                            inputType: "text",
                            dataType: (typeof formData.nama),
                            isReadonly: false,
                            isRequired: formData?.ucode ? true : false,
                        }
                    ]}
                    onValidate={(value) => true}
                    onClose={() => {}}
                    onSave={() => {}}
                />
            </FormContainer>
        </>
    );
};

export default Form;
