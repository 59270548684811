import { CircularProgress, Modal } from '@mui/material';
import { ReactElement } from 'react';

function Loader(): ReactElement {
    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="d-flex justify-content-center align-items-center loader w-100 h-100"
            disableEscapeKeyDown={true}
            sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                width: "100vh",
                height: "100vw",
            }}
        >
            <CircularProgress color="error" />
        </Modal>
    );
}

export default Loader;
