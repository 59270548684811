import FormContainer from "../../../components/FormContainer";
import { HelperText, Label, StyledInput } from "../../../components/_BaseContainer";

function Form() {
    return (
        <FormContainer
            groupName="Servis"
            subgroupName="Master"
            code="SERVICE-ESTIMASI"
            title="Estimasi"
        >
            <Label>Nama</Label>
            <StyledInput placeholder="Write your name here" />
            <HelperText />
        </FormContainer>
    );
};

export default Form;
