import { useCallback, useState } from "react";
import DataList from "../../../components/DataList";
import Karyawan from "../../../models/Karyawan";
import { useStateContext } from "../../../reducers";
import { DefaultMenuTypes, NotificationType } from "../../../utils/Types";
import PageContainer from "../../../components/PageContainer";
import NotificationDialog from "../../../components/NotificationDialog";
import Loader from "../../../components/Loader";
import { useNavigate } from 'react-router-dom';
import {
    Add as AddIcon,
    FileDownload as FileDownloadIcon,
	FileUpload as FileUploadIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
} from '@mui/icons-material';

function List() {
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [error, setError] = useState<NotificationType | null>(null);
    const [loading, setLoading] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<Karyawan | null>(null);
    const [dataToDelete, setDataToDelete] = useState<Karyawan | null>(null);
    const [dataToView, setDataToView] = useState<Karyawan | null>(null);
    const navigate = useNavigate();

	const handleAddClick = (pathObject: any) => {
		console.log("pathObject: ", pathObject);
		navigate(`${pathObject.mainPath}${pathObject.formPath}`, {state: {ucode: dataToEdit?.ucode}}); // Navigate to the form page for adding a new Karyawan
	};

	const handleEditClick = useCallback(() => {
        if (!dataToEdit)
            return;

        navigate(`/karyawan/edit`, {state: {ucode: dataToEdit?.ucode}});
    }, [navigate, dataToEdit]);

	const handleDeleteClick = useCallback(() => {
        if (!dataToDelete)
            return;

        navigate(`/karyawan/edit/${dataToDelete.ucode}`);
    }, [navigate, dataToDelete]);

    return (
		<>
        {
            loading && <Loader />
        }						
        <PageContainer
            groupName="Umum"
            subgroupName="Master"
            code="GLOBAL-KARYAWAN"
            title="Karyawan"
			toolbars={[
				{
					id: "add",
					title: "Add Karyawan",
					icon: AddIcon,
					action: (pathObject) => handleAddClick(pathObject),
				},
				{
					id: "exportExcel",
					title: "Export to Excel",
					icon: FileDownloadIcon,
					action: () => {},
					color: "secondary",
				},
				{
					id: "importExcel",
					title: "Import from Excel",
					icon: FileUploadIcon,
					action: () => {},
					color: "secondary",
				},					
			]}						
        >
            <DataList<Karyawan>
                dataType={Karyawan}
                dataFieldsToShow={["nama_karyawan", "foto_path"]}
                useDummyData={true}
                setError={(errorState) => setError(errorState ?? null)}
                setLoading={(loadingState) => setLoading(loadingState ?? false)}
				actions={[
					{
						id: "Edit" as DefaultMenuTypes,
						title: "Ubah",
						action: (item) => setDataToEdit(item),
						icon: EditIcon,
						color: "dark",
					},
					{
						id: "View" as DefaultMenuTypes,
						title: "Lihat",
						action: (item) => setDataToView(item),
						icon: SearchIcon,
						color: "view",
					},
					{
						id: "Delete" as DefaultMenuTypes,
						title: "Hapus",
						action: (item) => setDataToDelete(item),
						icon: DeleteIcon,
						color: "delete",
					},
				]}					
            />
        </PageContainer>
        {
            dataToView ?
			<div>
			{/* View Modal Implementation */}
			</div>
            :
            null
        }
        {/* {
            error ?
            <NotificationDialog
                {...error}
                onClose={() => setError(null)}
            />
            :
            null
        } */}
		</>			
    );
};

export default List;