import BaseModel from "./_BaseModel";

export default class Role extends BaseModel {
    public readonly ucode?: string;
    public kode?: string;
    public nama?: string;
    public level?: string;
    public spesifik_gudang: boolean = false;
    public aktif: boolean = true;
    public keterangan?: string;

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
};
