export type AuthMenuType = {
    kode_menu: string;
    nama_menu: string;
    can_add: boolean;
    can_edit: boolean;
    can_delete: boolean;
    see_other_div: boolean;
}

export type AuthStateType = {
    username?: string,
    nama_karyawan?: string,
    ucode_divisi?: string,
    kode_divisi?: string,
    token?: string,
    level?: bigint,
    daftar_menu: AuthMenuType[],
    build_id?: string,
};
export type AuthStateActionType = {
    type: string,
    username?: string,
    nama_karyawan?: string,
    ucode_divisi?: string,
    kode_divisi?: string,
    token?: string,
    level?: bigint,
    daftar_menu?: AuthMenuType[],
};

export const authInitialState: AuthStateType = {
    daftar_menu: [],
};

export default function authReducer(state: any, action: AuthStateActionType): AuthStateType {
    switch (action.type) {
        case 'login':
            console.log({action});
            return {username: action.username, nama_karyawan: action.nama_karyawan, ucode_divisi: action.ucode_divisi, kode_divisi: action.kode_divisi, token: action.token, level: action.level, daftar_menu: action.daftar_menu ?? [], build_id: process.env.REACT_APP_BUILD_ID};
        case 'refresh':
            return {username: action.username, nama_karyawan: action.nama_karyawan, ucode_divisi: action.ucode_divisi, kode_divisi: action.kode_divisi, token: action.token, level: action.level, daftar_menu: action.daftar_menu ?? [], build_id: process.env.REACT_APP_BUILD_ID};
        case 'logout':
            // console.log({check: 'caches' in window});
            if('caches' in window)
                caches.keys().then((names) => {
                    // Delete all the cache files
                    // console.log({names, caches: caches.keys()});
                    names.forEach(name => {
                        console.log({cacheName: name});
                        caches.delete(name);
                    });
                });
            
            localStorage.clear();
            return {...authInitialState};
        default:
            throw new Error();
    }
}
