import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import FormContainer from "../../../components/FormContainer";
import GrupBarang from '../../../models/GrupBarang';
import { useStateContext } from '../../../reducers';
import DataForm from '../../../components/DataForm';
import Loader from '../../../components/Loader';

function Form() {
	const location = useLocation();
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState<GrupBarang>({ grup_id: '', nama_grup: '' });

	useEffect(() => {
		if (!location?.state?.ucode)
            return;

        setLoading(true);
		apiCaller?.get(`grup_barang/${location.state.ucode}`)
            .then((response) => setFormData({...response.data}))
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
	}, [location.state, formData, apiCaller]);

    const onChanged = useCallback((valueObj: any) => {
        const {fieldName, value} = valueObj;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    }, [formData]);

    const onValidate = useCallback((data: GrupBarang | undefined) => {
        return true;
    }, []);

    return (
		<>
        {
            loading && <Loader />
        }
            <FormContainer
                groupName="Stok"
                subgroupName="Master"
                code="STOCK-GRUP-BRG"
                title="Grup Barang"
            >
                <DataForm<GrupBarang>
                    groupName="Stok"
                    subgroupName="Master"
                    code="STOCK-GRUP-BRG"
                    title="Grup Barang"
                    data={formData}
                    fields={[
                        {
                            name: "grup id",
                            title: "Grup ID",
                            inputType: "text",
                            dataType: (typeof formData.grup_id),
                            isReadonly: false,
                            isRequired: formData?.ucode ? true : false,
                            onChanged: (valueObj) => onChanged(valueObj),
                        },
                        {
                            name: "nama grup",
                            title: "Nama Grup",
                            inputType: "text",
                            dataType: (typeof formData.nama_grup),
                            isReadonly: false,
                            isRequired: true,
                            onChanged: (valueObj) => onChanged(valueObj),
                        }
                    ]}
                    onValidate={onValidate}
                    onClose={() => {}}
                    onSave={() => {}}
                />
            </FormContainer>		
		</>
    );
};

export default Form;
