import { AppBar, Box, Button, ButtonGroup, SvgIconTypeMap, Tab, Tabs, ThemeProvider, Tooltip } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../reducers";
import { Menu, MenuCode, MenuGroupName, MenuTitle } from "../utils/Menu";
import { FunctionObjectType } from "../utils/Types";
import BaseContainer from "./_BaseContainer";
import { PageContainerActionParamType, drawerWidth, getPathObject, headerHeight, pageTabHeight, pageToolbarHeight } from "../utils/Common";
import { GridColorStyles } from "../expanded-theme";

type PageToolbarType = {
    id: string,
    title: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string},
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'dark',
    variant?: "text" | "contained" | "outlined",
    action: FunctionObjectType<PageContainerActionParamType | null>,
};

type PageContainerType = {
    groupName: MenuGroupName,
    subgroupName: string,
    code: MenuCode,
    title: MenuTitle,
    toolbars?: PageToolbarType[],
    children: any,
};

type TabItemType = {
    code: MenuCode;
    title: string;
    path: string;
    component?: ReactElement;
    isDefault: boolean;
    selected: boolean;
}

function PageContainer(props: PageContainerType) {
    const {groupName, subgroupName, code, title, children, toolbars} = props;
    const {menuState} = useStateContext();
    const navigate = useNavigate();

    const tabs = useMemo(() => {
        const result: TabItemType[] = [];

        if (!Menu.filter(m => m.groupName === groupName && m.id === subgroupName && m.children.length).length)
            return result;

        Menu.filter(m => m.groupName === groupName && m.id === subgroupName && m.children.length).forEach(m => {
            m.children.forEach(c => {
                result.push({
                    ...c,
                    path: `${m.path}${c.path}`,
                    selected: code === c.code,
                });
            });
        });

        return result;
    }, [groupName, subgroupName, code]);

    const pathObject: PageContainerActionParamType | null = useMemo(() => {
        return getPathObject(groupName, subgroupName, code);
    }, [groupName, subgroupName, code]);

    // State to manage the selected tab index
    const [selectedTabIndex, setSelectedTabIndex] = useState(tabs.findIndex(t => t.selected));

    const handleTabChange = useCallback((event: React.SyntheticEvent, value: number) => {
        if (!tabs.length) {
            return;
        }

        const currentTab = tabs[value];
        setSelectedTabIndex(value); // Update the selected tab index state        
        navigate(currentTab.path);
    }, [tabs, navigate]);

    return (
        <BaseContainer>
        {
            tabs.length > 0 ?
            <AppBar
                position="fixed"
                sx={{
                    marginTop: `${headerHeight}px`,
                    boxShadow: 0,
                    width: { md: menuState?.showSidebar ? `calc(100vw - ${drawerWidth}px)` : "100vw" },
                    ml: { md: `${drawerWidth}px` },
                    height: `${pageTabHeight}px`,
                }}
                className="bg-color-page-tab"
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>
                    <Tabs value={tabs.indexOf(tabs.filter(t => t.selected)[0])} onChange={handleTabChange} aria-label="basic tabs example">
                    {
                        tabs.map((t, idx) => {
                            return (
                                <Tab label={t.title} id={`tab_${idx}`} key={`tab_${idx}`} />
                            );
                        })
                    }
                    </Tabs>
                </Box>
            </AppBar>
            :
            <></>
        }
        {
            toolbars?.length ?
            <AppBar
                position="fixed"
                sx={{
                    marginTop: `${headerHeight + pageTabHeight}px`,
                    boxShadow: "0 1px 6px 1px rgba(200, 200, 200, 0.7)",
                    width: { md: menuState?.showSidebar ? `calc(100vw - ${drawerWidth}px)` : "100vw" },
                    ml: { md: `${drawerWidth}px` },
                    height: `${pageToolbarHeight}px`,
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    marginBottom: "0.5rem",
                }}
                className="bg-color-page-tab"
            >
                <ThemeProvider theme={GridColorStyles}>
                    <ButtonGroup>
                    {
                        toolbars.map((t, t_idx) => (
                            <Tooltip
                                key={`toolbar_${t_idx}`}
                                title={t.title}
                                arrow
                            >
                                <Button variant={t.variant ?? "contained"} color={t.color ?? "primary"} onClick={() => t.action(pathObject)}>
                                    <t.icon />
                                </Button>
                            </Tooltip>
                        ))
                    }
                    </ButtonGroup>
                </ThemeProvider>
            </AppBar>
            :
            null
        }
        {
            tabs.length > 0 ?
            <Box sx={{marginTop: `calc(${pageTabHeight + (toolbars?.length ? pageToolbarHeight : 0) + ((toolbars?.length ? pageToolbarHeight : 0) / 4)}px + 0.8rem)`, width: "100%"}} className="bg-color-transparent"></Box>
            :
            null
        }
        {
            children
        }
        </BaseContainer>
    );
}

export default PageContainer;
