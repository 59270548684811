import Axios from 'axios';
import { AuthStateType } from '../reducers/auth';

export class ApiCallerProperties {
    public authState?: AuthStateType;
    public username?: string;
}

type urlResultType = {
    url: string,
    headers?: any,
}

export default class ApiCaller {
    public headers: {
        sender?: string,
        Authorization?: string,
        kode_divisi?: string,
    } = {};

    constructor(props: ApiCallerProperties) {
        this.headers = {};

        if (props.authState && props.authState.username && props.authState.username !== "")
            this.headers.sender = props.authState.username;

        if (props.authState && props.authState.token && props.authState.token !== "")
            this.headers.Authorization = `Bearer ${props.authState.token}`;

        if (props.authState && props.authState.kode_divisi && props.authState.kode_divisi !== "")
            this.headers.kode_divisi = props.authState.kode_divisi;
    }

    getUrlSetup(url: string, customHeaders?: any): urlResultType {
        const result: urlResultType = {url: `${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`};

        if (this.headers.sender || this.headers.Authorization || this.headers.kode_divisi)
            result.headers = this.headers;

        if (customHeaders)
            result.headers = {...result.headers, ...customHeaders};
        
        return result;
    }

    get(url: string, customHeaders?: any) {
        if (this.headers.sender || this.headers.Authorization || this.headers.kode_divisi)
            return Axios.get(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});
        
        return Axios.get(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ?? null});
    }

    post(url: string, data?: any, customHeaders?: any) {
        if (this.headers.sender || this.headers.Authorization || this.headers.kode_divisi)
            return Axios.post(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});
        
        return Axios.post(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ?? null});
    }

    put(url: string, data?: any, customHeaders?: any) {
        if (this.headers.sender || this.headers.Authorization || this.headers.kode_divisi)
            return Axios.put(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});

        return Axios.put(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, data, {headers: customHeaders ?? null});
    }

    delete(url: string, customHeaders?: any) {
        if (this.headers.sender || this.headers.Authorization || this.headers.kode_divisi)
            return Axios.delete(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ? this.headers : {...this.headers, ...customHeaders}});
        
        return Axios.delete(`${process.env.REACT_APP_EXTERNAL_API_ENDPOINT}${url}`, {headers: customHeaders ?? null});
    }
};
