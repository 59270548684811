import BaseModel from "./_BaseModel";

export default class GrupBarang extends BaseModel {
    public readonly ucode?: string;
    public grup_id?: string;
    public nama_grup?: string;
    public kode_barang?: boolean = true;

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
}