import BaseModel from "./_BaseModel";

export default class Posisi extends BaseModel {
    public readonly ucode?: string;
    public kode?: string;
    public nama?: string;
    public aktif: boolean = true;

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
}