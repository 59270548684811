import BaseModel from "./_BaseModel";

export default class Divisi extends BaseModel {
    public readonly ucode?: string;
    public kode?: string;
    public nama?: string;
    public logo?: string;
    public tipe_file_logo?: string;
    public keterangan?: string;
    public alamat?: string;
    public kota?: string;
    public tlp?: string;
    public parent?: string;
    public npwp?: string;
    public rek_name?: string;
    public rek_number?: string;
    public rek_qr?: string;
    public readonly timezone?: string;
    public aktif?: boolean = true;

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
}