import { colors } from "@mui/material";

export const GlobalStyleTemplate = {
    span: {fontSize: "0.75rem"},
    p: {fontSize: "0.9rem !important"},
    h1: {colors: colors.cyan,},
    '.no-padding': {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },
};
