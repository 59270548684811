import { ReactElement, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import { StateContextPropertyType, useStateContext } from "../reducers";
import { Menu } from "./Menu";

function AppRoutes() {
    const {authState}: StateContextPropertyType = useStateContext();

    const routes = useMemo(() => {
        const parsedMenus: ReactElement[] =[];
        // Menu.filter((m) => authState?.build_id && authState.daftar_menu?.length)
        Menu
            .filter(m => !m.isOnlyLink)
            .forEach((m, idx) => {
                if (!m.children?.filter(c => c.path).length)
                {
                    parsedMenus.push(<Route key={`route_${idx}`} path={m.path} element={m.component} />);

                    if (m.formPath && m.formComponent)
                        parsedMenus.push(<Route key={`route_${idx}_form`} path={m.formPath} element={m.formComponent} />);
                    
                    return;
                }

                m.children.filter(c => c.path).forEach((c, c_idx) => {
                    parsedMenus.push(<Route key={`route_${idx}_${c_idx}`} path={`${m.path}${c.path}`} element={c.component} />);

                    if (c.formPath && c.formComponent)
                        parsedMenus.push(<Route key={`route_${idx}_${c_idx}`} path={`${m.path}${c.formPath}`} element={c.formComponent} />);
                });
            })

        return parsedMenus;
    }, [authState]);

    return (
        <Routes>
            <Route path="/" element={authState?.token && authState.token !== "" ? <Dashboard /> : <Navigate to="/login" replace />} />
            <Route path="/dashboard" element={<Navigate to="/" replace />} />
            {
                routes
            }
            {/* <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/invoice/print" element={<InvoicePrint />} />
            <Route path="/invoice/do/print" element={<DeliveryOrderReceiptCustomerPrint />} />
            <Route path="/do/print" element={<DeliveryOrderPrint />} />
            <Route path="/do/receipt/print" element={<DeliveryOrderReceiptPrint />} /> */}
        </Routes>
    )
};

export default AppRoutes;

export function OtherRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
    );
};
