import { useCallback, useEffect, useState } from "react";
import DataList from "../../../components/DataList";
import Divisi from "../../../models/Divisi";
import { useStateContext } from "../../../reducers";
import { DefaultMenuTypes, NotificationType } from "../../../utils/Types";
import PageContainer from "../../../components/PageContainer";
import Loader from "../../../components/Loader";
import { useNavigate } from 'react-router-dom';
import {
    Add as AddIcon,
    FileDownload as FileDownloadIcon,
	FileUpload as FileUploadIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import { MenuCode, MenuGroupName } from "../../../utils/Menu";
import { PageContainerActionParamType, getPathObject } from "../../../utils/Common";

const groupName: MenuGroupName = "Umum";
const subgroupName: string = "Master";
const code: MenuCode = "GLOBAL-DIVISI";

function pathObject(): (PageContainerActionParamType | null) {
    return getPathObject(groupName, subgroupName, code);
};

function List() {
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [error, setError] = useState<NotificationType | null>(null);
    const [loading, setLoading] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<Divisi | null>(null);
    const [dataToDelete, setDataToDelete] = useState<Divisi | null>(null);
    const [dataToView, setDataToView] = useState<Divisi | null>(null);
    const [needReload, setNeedReload] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setNeedReload(true);
    }, []);

    useEffect(() => {
        if (!needReload)
            return;

        setNeedReload(false);
    }, [needReload]);

	const handleAddClick = (pathObject: any) => {
		navigate(`${pathObject.mainPath}${pathObject.formPath}`, {state: {ucode: dataToEdit?.ucode}});
	};

	const handleEditClick = useCallback((pathObject: any) => {
        if (!dataToEdit)
            return;

		navigate(`${pathObject.mainPath}${pathObject.formPath}`, {state: {...dataToEdit}});
    }, [navigate, dataToEdit]);

    useEffect(() => {
        if (!dataToEdit)
            return;

        handleEditClick(pathObject());
    }, [dataToEdit]);

	const handleDeleteClick = useCallback(() => {
        if (!dataToDelete || !apiCaller)
            return;

        setLoading(true);

        apiCaller.delete(`posisi/${dataToDelete.ucode}`)
            .then(() => {
                setLoading(false);
                setNeedReload(true);
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller, dataToDelete]);

    useEffect(() => {
        if (!dataToDelete)
            return;

        handleDeleteClick();
    }, [dataToDelete]);

    return (
        <>
        {
            loading && <Loader />
        }
            <PageContainer
                groupName="Umum"
                subgroupName="Master"
                code="GLOBAL-DIVISI"
                title="Divisi"
                toolbars={[
                    {
                        id: "add",
                        title: "Tambah Divisi",
                        icon: AddIcon,
                        action: (pathObject) => handleAddClick(pathObject),
                    },
                    {
                        id: "exportExcel",
                        title: "Download Excel",
                        icon: FileDownloadIcon,
                        action: () => {},
                        color: "dark",
                    },
                    {
                        id: "importExcel",
                        title: "Upload",
                        icon: FileUploadIcon,
                        action: () => {},
                        color: "dark",
                    },					
                ]}
            >
                <DataList<Divisi>
                    dataType={Divisi}
                    dataFieldsToShow={["kode", "nama", "alamat", "kota", "tlp", "foto"]}
					setError={(errorState) => setError(errorState ?? null)}
                    setLoading={(loadingState) => setLoading(loadingState ?? false)}
                    actions={[
                        {
                            id: "Edit" as DefaultMenuTypes,
                            title: "Ubah",
                            action: (item) => setDataToEdit(item),
                            icon: EditIcon,
                            color: "edit",
                        },
                        {
                            id: "View" as DefaultMenuTypes,
                            title: "Lihat",
                            action: (item) => setDataToView(item),
                            icon: SearchIcon,
                            color: "view",
                        },
                        {
                            id: "Delete" as DefaultMenuTypes,
                            title: "Hapus",
                            action: (item) => setDataToDelete(item),
                            icon: DeleteIcon,
                            color: "delete",
                        },
                    ]}
                    apiCaller={apiCaller}
                    endpoint="divisi"
                    titleColumnName="nama"
                    subtitleColumnName="kode"
                    details={[
                        {fieldName: "alamat", title: "Alamat"},
                        {fieldName: "kota", title: "Kota"}
                    ]}
                    needReload={needReload}
                />
            </PageContainer>
        {
            dataToView ?
			<div>
			{/* View Modal Implementation */}
			</div>
            :
            null
        }
        {/* {
            error ?
            <NotificationDialog
                {...error}
                onClose={() => setError(null)}
            />
            :
            null
        } */}
        </>
    );
};

export default List;