import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import FormContainer from "../../../components/FormContainer";
import Posisi from '../../../models/Posisi';
import { useStateContext } from '../../../reducers';
import DataForm from '../../../components/DataForm';
import { PageContainerActionParamType } from '../../../utils/Common';
import Loader from '../../../components/Loader';
import { SaveDataParamType } from '../../../utils/Types';

function Form() {
	const location = useLocation();
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<Posisi>({ kode: '', nama: '', aktif: true });
    const navigate = useNavigate();

	useEffect(() => {
		if (!location?.state?.ucode)
            return;

        setLoading(true);
		apiCaller?.get(`posisi/id?ucode=${location.state.ucode}`)
            .then((response) => setFormData({...response.data?.data}))
            .catch((error) => console.error({error}))
            .finally(() => setLoading(false))
        ;
	}, [location.state, apiCaller]);

    const onChanged = useCallback((valueObj: any) => {
        const {fieldName, value} = valueObj;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    }, [formData]);

    const onValidate = useCallback((data: Posisi | undefined) => {
        if (!data?.nama)
            return false;

        if (data?.ucode && !data.kode)
            return false;

        return true;
    }, []);

    const onClose = useCallback((pathObject: PageContainerActionParamType | null | undefined) => {
        console.log({pathObject});
        navigate(`${pathObject?.mainPath}${pathObject?.path}`);
    }, [navigate]);

    const onSave = useCallback((param: SaveDataParamType<Posisi> | undefined) => {
        console.log({param});

        if (!param?.data || !apiCaller)
            return;

        setLoading(true);

        const apiCallerObj = param.data.ucode ? apiCaller.put(`posisi`, JSON.stringify(param.data)) : apiCaller.post(`posisi`, JSON.stringify(param.data));

        apiCallerObj
            .then(() => {
                setLoading(false);

                if (param.callback)
                    param.callback();
            })
            .catch((error) => {
                console.error({error});
                setLoading(false);
            })
        ;
    }, [apiCaller]);

    return (
        <>
        {
            loading && <Loader />
        }
            <FormContainer
                groupName="Umum"
                subgroupName="Master"
                code="GLOBAL-POSISI"
                title="Jabatan"
            >
                <DataForm<Posisi>
                    groupName="Umum"
                    subgroupName="Master"
                    code="GLOBAL-POSISI"
                    title="Jabatan"
                    data={formData}
                    fields={[
                        {
                            name: "kode",
                            title: "Kode",
                            inputType: "text",
                            dataType: (typeof formData.kode),
                            isReadonly: false,
                            isRequired: formData?.ucode ? true : false,
                            onChanged: (valueObj) => onChanged(valueObj),
                        },
                        {
                            name: "nama",
                            title: "Nama",
                            inputType: "text",
                            dataType: (typeof formData.nama),
                            isReadonly: false,
                            isRequired: true,
                            onChanged: (valueObj) => onChanged(valueObj),
                        }
                    ]}
                    onValidate={onValidate}
                    onClose={(pathObject) => onClose(pathObject)}
                    onSave={(data) => onSave(data)}
                />
            </FormContainer>
        </>
    );
};

export default Form;
