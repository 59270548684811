import FormContainer from "../../../components/FormContainer";
import { HelperText, Label, StyledInput } from "../../../components/_BaseContainer";

function Form() {
    return (
        <FormContainer
            groupName="Umum"
            subgroupName="Pengaturan"
            code="GLOBAL-USER"
            title="User"
        >
            <Label>Nama</Label>
            <StyledInput placeholder="Write your name here" />
            <HelperText />
        </FormContainer>
    );
};

export default Form;
