import BaseModel from "../models/_BaseModel";

function randomize(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function generateRandomData<T extends BaseModel>(dataType: {new(): T}, rowCount: number): T[] {
    const result: T[] = [];

    for(let i = 0; i < rowCount; i++)
    {
        const item = new dataType();
        type itemType = keyof typeof item;
        
        Object.keys(item).forEach(k => {
            const kType = k as itemType;
            (item[kType] as any) = (typeof kType === "number" ? Math.random(): (typeof kType === "string" ? randomize(15) : typeof kType === "boolean" ? true : null));
        });

        result.push(item);
    }

    console.log({result});
    return result;
}
