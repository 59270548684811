import UserRole from "./UserRole";
import BaseModel from "./_BaseModel";

export default class Karyawan extends BaseModel {
    public readonly ucode?: string;
    public nama?: string;
    public foto_path?: string;
    public aktif?: boolean = true;
    public userRoles?: UserRole[] = [];

    public constructor(
        ucode?: string
    ) {
        super();
        this.ucode = ucode;
    }
}