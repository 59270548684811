import { createTheme, responsiveFontSizes } from '@mui/material/styles';

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        dark: true;
        edit: true;
        delete: true;
        view: true;
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        dark: Palette['primary'];
        edit: Palette['primary'];
        delete: Palette['primary'];
        view: Palette['primary'];
    }
    interface PaletteOptions {
        dark: PaletteOptions['primary'];
        edit: PaletteOptions['primary'];
        delete: PaletteOptions['primary'];
        view: PaletteOptions['primary'];
    }
}
  
const theme = createTheme({
    palette: {
        dark: {
            main: '#444',
            light: '#888',
            dark: '#222',
            contrastText: '#FFF',
        },
        edit: {
            main: '#0046a1',
            light: '#0058c9',
            dark: '#003a85',
            contrastText: '#FFF',
        },
        delete: {
            main: '#c20000',
            light: '#eb0000',
            dark: '#940000',
            contrastText: '#FFF',
        },
        view: {
            main: '#009e3f',
            light: '#00d153',
            dark: '#008234',
            contrastText: '#FFF',
        },
    },
});

export const GridColorStyles = responsiveFontSizes(theme);
