import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu as MenuIcon } from '@mui/icons-material';
import { StateContextPropertyType, useStateContext } from "../reducers";
import { drawerWidth, headerHeight } from "../utils/Common";

function Navbar()
{
    const {menuState, setMenuState}: StateContextPropertyType = useStateContext();

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: menuState?.showSidebar ? `calc(100vw - ${drawerWidth}px)` : "100vw" },
                ml: { md: `${drawerWidth}px` },
                height: headerHeight
            }}
            className="bg-color-default"
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="Buka Menu"
                    edge="start"
                    onClick={() => {
                        setMenuState({...menuState, type: "toggle", showSidebar: !menuState?.showSidebar ?? false});
                    }}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    Booking Service
                </Typography>
            </Toolbar>
        </AppBar>
    )
};

export default Navbar;
