import { useState } from "react";
import DataList from "../../../components/DataList";
import Satuan from "../../../models/Satuan";
import { useStateContext } from "../../../reducers";
import { DefaultMenuTypes, NotificationType } from "../../../utils/Types";
import PageContainer from "../../../components/PageContainer";
import NotificationDialog from "../../../components/NotificationDialog";
import Loader from "../../../components/Loader";

function List() {
    const {authState, setAuthState, apiCaller} = useStateContext();
    const [error, setError] = useState<NotificationType | null>(null);
    const [loading, setLoading] = useState(false);
    const [dataToEdit, setDataToEdit] = useState<Satuan | null>(null);
    const [dataToDelete, setDataToDelete] = useState<Satuan | null>(null);

    return (
        <>
        {
            loading && <Loader />
        }
            <PageContainer
                groupName="Stok"
                subgroupName="Master"
                code="STOCK-SATUAN"
                title="Satuan"
            >
                <DataList<Satuan>
                    dataType={Satuan}
                    dataFieldsToShow={["kode", "nama", "alamat", "kota", "tlp"]}
                    useDummyData={true}
                    setError={(errorState) => setError(errorState ?? null)}
                    setLoading={(loadingState) => setLoading(loadingState ?? false)}
                    actions={[
                        {
                            id: "Edit" as DefaultMenuTypes,
                            title: "Ubah",
                            action: (item) => setDataToEdit(item),
                        },
                        {
                            id: "Delete" as DefaultMenuTypes,
                            title: "Hapus",
                            action: (item) => setDataToDelete(item),
                        },
                    ]}
                />
            </PageContainer>
        {
            error ?
            <NotificationDialog
                {...error}
                onClose={() => setError(null)}
            />
            :
            null
        }
        </>
    );
};

export default List;