import { Autocomplete, Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Input, InputLabel, TextField, Typography } from "@mui/material";
import BaseModel from "../models/_BaseModel";
import { MutableRefObject, createRef, useCallback, useEffect, useMemo } from "react";
import { MenuCode, MenuGroupName, MenuTitle } from "../utils/Menu";
import { FunctionObjectType, FunctionObjectWithReturnValueType, SaveDataParamType } from "../utils/Types";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import '../styles/all.css';
import { DATETIME_FORMAT_DEFAULT, PageContainerActionParamType, dateToString, getPathObject } from "../utils/Common";
import { useStateContext } from "../reducers";

type InputType = "text" | "textarea" | "checkbox" | "dropdown" | "combobox" | "date" | "datetime" | "file";

type DataFormFieldChangeParamType = {
    fieldName: string,
    value: any,
};

type DataFormFieldType = {
    name: string,
    title: string,
    inputType: InputType,
    dataType: string | boolean | number | BaseModel | null,
    dataTypeModelValueField?: string,
    dataTypeModelLabelField?: string,
    isReadonly: boolean,
    isRequired: boolean,
    searchEndpoint?: string,
    searchEndpointFilterParamName?: string,
    onChanged?: FunctionObjectType<DataFormFieldChangeParamType>,
};

type DataFormType<T extends BaseModel> = {
    groupName: MenuGroupName,
    subgroupName: string,
    code: MenuCode,
    title: MenuTitle,
    data: T,
    fields: DataFormFieldType[],
    onValidate: FunctionObjectWithReturnValueType<T, boolean>,
    onClose: FunctionObjectType<PageContainerActionParamType | null>,
    onSave: FunctionObjectType<SaveDataParamType<T>>,
};

function DataForm<T extends BaseModel>(props: DataFormType<T>) {
    const {
        groupName,
        subgroupName,
        code,
        title,
        fields,
        data,
        onValidate,
        onClose,
        onSave,
    } = props;

    const {apiCaller} = useStateContext();

    const pathObject: PageContainerActionParamType | null = useMemo(() => {
        return getPathObject(groupName, subgroupName, code);
    }, [groupName, subgroupName, code]);

    const onLoadComboboxData = useCallback((url: string | undefined, ref: MutableRefObject<any>, filterParamName: string | null = null, filter: string | null = null) => {
        if (!url)
            return;

        apiCaller?.get(`${url}${filter ? `?${filterParamName}=${filter}` : ""}`)
            .then((response) => {
                ref.current = response.data;
            })
            .catch((error) => console.error({error}))
        ;
    }, [apiCaller]);

    const onChange = useCallback((valueObj: DataFormFieldChangeParamType, onChangeEvent?: FunctionObjectType<DataFormFieldChangeParamType>) => {
        if (onChangeEvent)
            onChangeEvent(valueObj);
    }, []);

    const handleSave = useCallback((closeAfterSave: boolean = false) => {
        if (!onValidate(data))
            return;

        onSave({
            data,
            callback: () => {
                if (!closeAfterSave)
                    return;

                onClose(pathObject);
            }
        });
    }, [data, onValidate, onSave, onClose, pathObject]);

    const boxHeaderStyle = useMemo(() => {
        return {display: "flex", justifyContent: "start", width: "100%"};
    }, []);

    const boxInputStyle = useMemo(() => {
        return {display: "flex", justifyContent: "center", width: "100%"};
    }, []);

    const boxFooterStyle = useMemo(() => {
        return {display: "flex", justifyContent: "start", width: "100%", marginTop: "0.75rem"};
    }, []);

    return (
        <>
            <Box sx={{...boxHeaderStyle}}>
                <Typography variant="h6" noWrap component="div">
                    {`${(data as any)?.ucode ? "Ubah data" : "Tambah data"} ${title}`}
                </Typography>
            </Box>
            <Box sx={{...boxInputStyle}}>
                <Grid container spacing={2}>
                {
                    fields.map((f, idx) => {
                        const value = (data as any)[f.name];
                        const labelProps: any = {
                            required: f.isRequired
                        };
                        const inputTextProps: any = {
                            size: "small",
                            value: value ?? "",
                            onChange: (e: any) => onChange({fieldName: f.name, value: e.target.value}, f.onChanged),
                            required: f.isRequired,
                            autocomplete: "off"
                        }
                        const inputTextareaProps: any = {
                            size: "small",
                            value: value ?? "",
                            multiline: true,
                            maxRows: 6,
                            onChange: (e: any) => onChange({fieldName: f.name, value: e.target.value}, f.onChanged),
                            required: f.isRequired,
                            autocomplete: "off"
                        }
                        const inputCheckboxProps: any = {
                            size: "small",
                            onChange: (e: any) => onChange({fieldName: f.name, value: e.target.value}, f.onChanged),
                            required: f.isRequired,
                            control: <Checkbox checked={value} />,
                            label: f.title
                        }
                        const inputDateProps: any = {
                            size: "small",
                            label: f.title,
                            value: value ? moment(value) : null,
                            onChange: (changeValue: any) => onChange({fieldName: f.name, value: changeValue ? dateToString(changeValue) : null}),
                            renderInput: (params: any) => <TextField {...params} />
                        };
                        const inputDateTimeProps: any = {
                            size: "small",
                            label: f.title,
                            value: value ? moment(value) : null,
                            onChange: (changeValue: any) => onChange({fieldName: f.name, value: changeValue ? dateToString(changeValue, DATETIME_FORMAT_DEFAULT) : null}),
                            renderInput: (params: any) => <TextField {...params} />
                        };
                        const inputComboboxProps: any = {
                            size: "small",
                            fullWidth: true,
                            filter: (searchText: string, key: string) => true
                        };

                        if (f.inputType === "combobox")
                        {
                            const ref = createRef();
                            inputComboboxProps.dataSource = ref.current;
                            inputComboboxProps.onUpdateInput = (filter: string) => onLoadComboboxData(f.searchEndpoint, ref, f.searchEndpointFilterParamName, filter);
                        }

                        return (
                            <Grid
                                item
                                lg={f.inputType === "file" ? 12 : 3}
                                md={f.inputType === "file" ? 12 : 6}
                                sm={f.inputType === "file" ? 12 : 12}
                                key={`data_input_${idx}`}
                            >
                                <FormGroup>
                                    <InputLabel {...labelProps}>{f.title}</InputLabel>
                                {
                                    f.isReadonly ?
                                    <InputLabel>{value}</InputLabel>
                                    :
                                    (
                                        f.inputType === "text" ?
                                        <TextField {...inputTextProps}/>
                                        :

                                        f.inputType === "textarea" ?
                                        <Input {...inputTextareaProps}/>
                                        :

                                        f.inputType === "checkbox" ?
                                        <FormControlLabel {...inputCheckboxProps}/>
                                        :

                                        f.inputType === "date" ?
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker {...inputDateProps}/>
                                        </LocalizationProvider>
                                        :

                                        f.inputType === "datetime" ?
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DateTimePicker {...inputDateTimeProps}/>
                                        </LocalizationProvider>
                                        :

                                        f.inputType === "combobox" ?
                                        <Autocomplete {...inputComboboxProps} />
                                        :

                                        null
                                    )
                                }
                                </FormGroup>
                            </Grid>
                        );
                    })
                }
                </Grid>
            </Box>
            <Box sx={{...boxFooterStyle}}>
                <Button
					variant="contained"
                    onClick={() => handleSave(true)}
                >
                    Simpan
                </Button>
				&nbsp;
                <Button
					variant="outlined"
                    onClick={() => onClose(pathObject)}
				>
					Batal
				</Button>
            </Box>
        </>
    );
}

export default DataForm;
